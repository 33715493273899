import React from 'react';
import '../../App.css';
import PlainCards from '.././plain-cards';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';


function Engineering() {

  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const loadContent = async () => {
        // simulate content loading delay for 2 seconds
        await new Promise(resolve => setTimeout(resolve, 500));
        setIsLoading(false);
      };

      loadContent();
    }, []);

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

  return (
    <div>
    <div className='App'>
      <h1>Introduction to Engineering and Design</h1><p>Finished with: 102.56%</p>
      <a href='https://drive.google.com/drive/folders/1TMg-GsOADQTAK01Z8HM7jX_i-ZmIK8Hg?usp=share_link'>
      <PlainCards 
        title='Semester 1'       
      />
      </a>
      <a href='https://drive.google.com/drive/folders/1aC5TT2135zWaSQoyjMEbog4si42CrcQU?usp=share_link'>
      <PlainCards 
        title='Semester 2'       
      />
      </a>
    </div>
    </div>
  );
}

export default Engineering;