import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './Main';
import Chemistry from './Components/Pages/chemistry';
import Engineering from './Components/Pages/engineering';
import Poe from './Components/Pages/poe';
import APlit from './Components/Pages/aplit';
import ModsPage from './Components/Pages/Mods';
import Music from './Components/Pages/Music';

function App() { 
  return (
    <>
      <Routes>
        <Route path="*" element={<Main />}/>
        <Route path="/Components/Pages/chemistry" element={<Chemistry />} />
        <Route path="/Components/Pages/engineering" element={<Engineering />} />
        <Route path="/Components/Pages/poe" element={<Poe />} />
        <Route path="/Components/Pages/aplit" element={<APlit />} />
        <Route path="/Components/Pages/Mods" element={<ModsPage />} />
        <Route path="/Components/Pages/Music" element={<Music />} />
      </Routes>
    </>
  );
}

export default App;