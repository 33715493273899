import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {Route, Link, Routes} from 'react-router-dom';
import './card.css';

interface PlainCardsProps {
    title: string;
    text?: string;
}

function PlainCards(props: PlainCardsProps) {
    
    return (
        <Row>
        <Col xs={12} md={6} lg={4}>
            <Card className='card-sub plain-cards'>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
                
            </Card.Body>
            </Card>
        </Col>
        </Row>
    );
}

export default PlainCards;