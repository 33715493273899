import { useState, useEffect } from "react";

function Status() {
  const [urlStatus, setUrlStatus] = useState("");

  async function checkUrlStatus(url: string) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        return "Online";
      } else {
        return "Error";
      }
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  useEffect(() => {
    async function checkStatus() {
      const status = await checkUrlStatus("https://subsite-py-44t6tafmyq-uc.a.run.app");
      setUrlStatus(status);
    }

    checkStatus();
  }, []);

  const statusStyle = {
    color: urlStatus === "Online" ? "green" : "red",
    fontWeight: "bold",
    //textTransform: "uppercase" as const // specify full property name and valid CSS value
  };

  return (
    <div>
      <p>GCloud Status: <span style={statusStyle}>{urlStatus}</span></p>
    </div>
  );
}

export default Status;