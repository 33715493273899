import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import GameMods from '../ModCardGrid';


function ModsPage() {

    const today = new Date().toLocaleDateString();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadContent = async () => {
            // simulate content loading delay for 2 seconds
            await new Promise(resolve => setTimeout(resolve, 500));
            setIsLoading(false);
        };

        loadContent();
    }, []);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="" style={{textAlign: 'center'}}>           
            <GameMods />
        </div>
    );
}

export default ModsPage;