import React from 'react';
import '../../App.css';
import PlainCards from '.././plain-cards';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';

function Poe() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const loadContent = async () => {
        // simulate content loading delay for 2 seconds
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsLoading(false);
      };

      loadContent();
    }, []);

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
        <div className='App'>
        <h1>Principles of Engineering</h1>
        <p>Current Grade: 93.21%</p>
        <br></br>
        <p style={{color: 'red'}}>Not yet implemented</p>
        </div>
    );
}

export default Poe;