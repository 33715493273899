import React from 'react';
import '../../App.css';
import PlainCards from '.././plain-cards';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';


function Chemistry() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadContent = async () => {
            // simulate content loading delay for 2 seconds
            await new Promise(resolve => setTimeout(resolve, 500));
            setIsLoading(false);
        };

        loadContent();
    }, []);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className='chemistry-page'>
            <nav className="nav" style={{ color: '#FFFFFF' }}>
                <a href="/about">Home</a>
                <button className="nav-button">Button</button>
            </nav>
            <div className='App'>
                <h1>Chemistry - Codux Tests</h1><p>Current Grade: 97.32%</p>
                <a href='https://drive.google.com/drive/folders/1JT-6NhLp4QNcNDP3G1w06sl26Zxk5pYN?usp=sharing'>
                    <PlainCards
                        title='Unit 4'
                    />
                </a>
                <a href='https://drive.google.com/drive/folders/1iq8_45ofH0DPpQcHxP6JBVdc83yZ9eeu?usp=sharing'>
                    <PlainCards
                        title='Unit 7'
                    />
                </a>
                <a href='https://drive.google.com/drive/folders/1DShtdoLafuX2lCWxKjFMlsuVYqkYQpIG?usp=share_link'>
                    <PlainCards
                        title='Unit 8'
                    />
                </a>
                <a href='https://drive.google.com/drive/folders/1B2zp4uHIZnxuXGzUsBIkw05YMblgPuZf?usp=share_link'>
                    <PlainCards
                        title='Unit 9'
                    />
                </a>
                <a href='https://drive.google.com/drive/folders/1Uq0OnKirWyJWXeJdjtd66vo-dpLiKCSl?usp=share_link'>
                    <PlainCards
                        title='Unit 10'
                    />
                </a>
            </div>
        </div>
    );
}

export default Chemistry;