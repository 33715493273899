import React from 'react';
import '../../App.css';
import PlainCards from '../plain-cards';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';

function APlit () {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const loadContent = async () => {
        // simulate content loading delay for 2 seconds
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsLoading(false);
      };

      loadContent();
    }, []);

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
        <div className='App'>
        <h1>Literature and Composition, AP</h1>
        <p>Current Grade: 95.53%</p>
        <PlainCards 
            title='Prose'
        />
        <PlainCards 
            title='Poetry'
        />
        <PlainCards 
            title='Multiple Choice'
        />
        <PlainCards
            title='Free Response'
        />
        </div>
    );
}

export default APlit;