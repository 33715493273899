import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Mod.css';

interface Mod {
    name: string;
    description: string;
    id: string;
    version: string;
    download: string;
    source: string;
    author: string;
    downloaded: boolean;
}  

const GameMods = () => {
  const [modData, setModData] = useState<{ [key: string]: Mod[] }>({});
  const [selectedVersion, setSelectedVersion] = useState<string>('');
  const [filteredMods, setFilteredMods] = useState<Mod[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    axios
      .get(
        'https://raw.githubusercontent.com/ComputerElite/ComputerElite.github.io/main/tools/Beat_Saber/mods.json'
      )
      .then((res) => setModData(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const savedVersion = localStorage.getItem("selectedVersion");
    if (savedVersion) {
      setSelectedVersion(savedVersion);
    }

    if (selectedVersion) {
      const mods = modData[selectedVersion];
      setFilteredMods(mods);
    }
  }, [selectedVersion, modData]);

  const today = new Date().toLocaleDateString();

  const handleVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVersion(event.target.value);
    const version = event.target.value;
    setSelectedVersion(version);
    localStorage.setItem("selectedVersion", version);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterMods = (mods: Mod[], searchTerm: string) => {
    return mods.filter((mod) => mod.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleModVersionChange = (event: React.ChangeEvent<HTMLSelectElement>, modId: string) => {
    const version = event.target.value;
    const newMods = [...filteredMods];
    const index = newMods.findIndex((mod) => mod.id === modId);
    newMods[index] = { ...newMods[index], version, downloaded: true };
    setFilteredMods(newMods);
  };  

  {/*function handleModVersionChange(event) {
    const selectedVersion = event.target.value;
    const modName = document.querySelector('#mod-name').textContent;
  
    // Modify this line to generate the correct download link based on the selected version
    const downloadLink = `https://example.com/${modName}-${selectedVersion}.zip`;
  
    // Update the download button link
    const downloadButton = document.querySelector('#download-button');
    downloadButton.href = downloadLink;
  }*/}
  
  

  const groupModsByName = (mods: Mod[]) => {
    if (!mods) return [];

    const modGroups: { [key: string]: Mod } = {};
    mods.forEach((mod) => {
      if (!modGroups[mod.id]) {
        modGroups[mod.id] = mod;
      } else {
        const existingMod = modGroups[mod.id];
        const existingVersion = existingMod.version;
        if (parseFloat(mod.version) > parseFloat(existingVersion)) {
          modGroups[mod.id] = mod;
        }
      }
    });
    const sortedMods = Object.values(modGroups).sort((a, b) => parseFloat(b.version) - parseFloat(a.version));
    return sortedMods;
  };
  

  const renderModGrid = () => {
    const modsToDisplay = filterMods(groupModsByName(filteredMods), searchTerm);
    return (
      <div className="mod-grid">
        {modsToDisplay.map((mod) => (
          <div key={mod.id} className="mod-card">
            <h3>{mod.name}</h3>
            <div className="mod-version-select">
              {modData[selectedVersion].filter((m) => m.id === mod.id).length > 1 ? (
                <select
                  value={mod.version}
                  onChange={(e) => handleModVersionChange(e, mod.id)}
                >
                  {modData[selectedVersion]
                    .filter((m) => m.id === mod.id)
                    .map((m) => (
                      <option key={m.version} value={m.version}>
                        {m.version}
                      </option>
                    ))}
                </select>
              ) : (
                <span>Version: {mod.version}</span>
              )}
            </div>
            <p>Author: {mod.author}</p>
            <p>{mod.description}</p>
            <div className="mod-details">
              <div className="mod-download">
                <button onClick={() => window.open(mod.download, "_blank")}>
                  Download
                </button>
                <button onClick={() => window.open(mod.source, "_blank")}>
                  Source
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  
  
    return (
        <div className="mod-page">
            <div className="mod-page-header">
                <h1 style={{textAlign: 'center'}}>Game Mods</h1>
                <p style={{textAlign: 'center'}}>Data pulled from: <a href='https://github.com/ComputerElite/ComputerElite.github.io/blob/main/tools/Beat_Saber/mods.json' target='_noreferer' style={{textDecoration: 'underline', color: 'aquamarine'}}>mods.json</a></p>
                <p style={{textAlign: 'center'}}>Made by: <a href='https://github.com/ArtemisE1Tara' style={{textDecoration: 'underline', color: 'aquamarine'}} target='_noreferer'>ArtemisE1Tara</a></p>
                <p>
                This page is maintained for the latest moddable version of Beat Saber on Quest.<br></br> As of {today}, the latest moddable version is 1.28.0.</p>
                <div className="mod-version-select-top">
                    <p style={{fontSize: '20px'}}>Game version: <select value={selectedVersion} onChange={handleVersionChange}>
                            <option value="">Select a version</option>
                            {Object.keys(modData)
                            .sort((a, b) => parseFloat(b) - parseFloat(a))
                            .map((version) => (
                                <option key={version} value={version}>
                                    {version}
                                </option>
                            ))}
                        </select>
                    </p>
                </div>
                <div className="mod-search">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            {selectedVersion && renderModGrid()}
            {/*<p style={{textAlign: 'center'}}>Beat Saber render provided by IPixelGuy</p>*/}
        </div>
    );
};

export default GameMods;