import './App.css';
import { Card, Col, Row } from 'react-bootstrap';
import './Components/card.css';
import {Route, Link, Routes} from 'react-router-dom';
import { useState, useEffect, lazy, Suspense } from 'react';
import { ComponentType } from 'react';
import React from 'react';
import Status from './Components/Status';
import DownloadForm from './Components/DownloadForm';

const src = "https://artemise1tara1.b-cdn.net/Rain%20-%20Quest%20Render.mp4"
const ChemistryCard: ComponentType<any> = React.lazy(() => import('./Components/Cards/ChemistryCard'));
const EngineeringCard: ComponentType<any> = React.lazy(() => import('./Components/Cards/POECard'));
const ModsCard: ComponentType<any> = React.lazy(() => import('./Components/Cards/ModsCard'));
const MusicCard: ComponentType<any> = React.lazy(() => import('./Components/Cards/MusicCard'));


function Main() {

  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(password); // add this line
    const response = await fetch('https://auth-44t6tafmyq-uc.a.run.app', {
      method: 'POST',
      body: JSON.stringify({ password }),
    });
    if (response.ok) {
      setAuthenticated(true);
      const content = await response.text();
      setContent(content);
    } else if (response.status === 401) {
      setAuthenticated(false);
      setContent('Access Denied');
    }
  };

  useEffect(() => {
    console.log('authenticated:', authenticated);
    console.log('content:', content);
  }, [authenticated, content]);

  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    try {
      setLoading(true);
      const response = await fetch('https://subsite-py-44t6tafmyq-uc.a.run.app/', {
        mode: 'cors'
      });
      const result = await response.text();
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  

  return (
    <>
    <div className="container">
    <div className="App">
      <h1>Course Materials</h1>
      <p>
        This is an initial version of a site to browse various course materials.
        <br />
        It is and still will be a work in progress for quite a while.
      </p>
      <div className="card-container">
        <div className="card-column">
          <Suspense fallback={<p style={{color: 'gray'}}>Loading...</p>}>
            <Link to={'./Components/Pages/chemistry'}><ChemistryCard /></Link>
          </Suspense>
        </div>
        <div className="card-column">
          <Suspense fallback={<p style={{color: 'gray'}}>Loading...</p>}>
            <Link to={'./Components/Pages/poe'}><EngineeringCard /></Link>
          </Suspense>
        </div>
        <div className="card-column">
          <Suspense fallback={<p style={{color: 'gray'}}>Loading...</p>}>
            <Link to={'./Components/Pages/Mods'}><ModsCard /></Link>
          </Suspense>
        </div>
        <div className="card-column">
          <Suspense fallback={<p style={{color: 'gray'}}>Loading...</p>}>
            <Link to={'./Components/Pages/Music'}><MusicCard /></Link>
          </Suspense>
        </div>
      </div>
      {/*<DownloadForm />*/}
      {/*<button onClick={handleClick}>Fetch Data</button>
      <div className="pre-container">
        {loading ? <p>Loading...</p> : data && <pre>{data}</pre>}
      </div>
    </div>
    <div className="status" style={{position: 'absolute', bottom: '0', left: '0', margin: '2%'}}>*/}
    <Status />
    </div>
    </div>
    </>
  );
};

export default Main;
