import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import '../../App.css';
import styled from 'styled-components';
import pdfjsLib from "pdfjs-dist";
import  MyPdfViewer   from '.././PDFViewer';

const Music: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const loadContent = async () => {
        // simulate content loading delay for 2 seconds
        await new Promise(resolve => setTimeout(resolve, 500));
        setIsLoading(false);
      };

      loadContent();
    }, []);

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
        <div className="App">
            <h1>Music</h1>
            <h2 style={{color: '#e3e3e3'}}>My favorite clarinet pieces</h2>
            <p style={{color: 'red'}}>Under construction</p>
        </div>
    );
}

export default Music;